// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
import linq from "linq";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "./img/image-error.png",
  loading: "./img/image-error.png",
  attempt: 1,
});

@Component({
  components: {},
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
    this.model.page.searchStatuses.push(
      new model.CheckBox("public", "公開中", false)
    );
    this.model.page.searchStatuses.push(
      new model.CheckBox("private", "非公開中", false)
    );
    this.model.page.searchStatuses.push(
      new model.CheckBox("preparation", "準備中", false)
    );
    this.$watch("model.page.searchStatuses", () => this.changeSearch(), {
      deep: true,
    });
  }
  public async mounted() {
    await this.authCheck();
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
    this.changePage();
  }
  @Watch("$route")
  public changePage() {
    if (this.$route.query.page != undefined) {
      this.model.page.pageCount = Number(this.$route.query.page);
      this.loadListRun();
    } else {
      this.changeSearch();
    }
  }
  @Watch("model.page.viewCount")
  @Watch("model.page.searchOrder")
  @Watch("model.page.searchKeyword")
  public changeSearch() {
    if (this.model.page) {
      this.model.page.pageCount = 1;
      history.pushState("", "", "?page=1");
      this.loadListRun();
    }
  }
  public loadListRun() {
    if (!this.model.loadingListProcess) {
      this.model.loadingList = true;
    }
    this.model.loadingListProcessCount++;
    this.model.contents = [];
    setTimeout(() => {
      this.model.loadingListProcessCount--;
      if (!this.model.loadingListProcess) {
        this.loadList();
      }
    }, 300);
  }
  public changeClearForm() {
    this.model.page.searchOrder = "new";
    this.model.page.viewCount = 10;
    this.model.page.searchKeyword = "";
    for (const item1 of this.model.page.searchStatuses) {
      item1.checked = false;
    }
    for (const item1 of this.model.page.searchContentGroups) {
      item1.checked = false;
    }
    for (const item1 of this.model.page.searchPlans) {
      item1.checked = false;
    }
    for (const item1 of this.model.page.searchTags) {
      item1.checked = false;
    }
    this.model.page.searchDetail = false;
  }
  public async loadList() {
    this.model.contents = [];
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/Contents/Main`,
        {
          page: this.model.page.pageCount,
          searchInfoLoad: !this.model.page.searchLoaded,
          search: {
            keyword: this.model.page.searchKeyword,
            viewCount: Number(this.model.page.viewCount),
            order: this.model.page.searchOrder,
            plans: linq
              .from(this.model.page.searchPlans)
              .where((x) => x.checked == true)
              .select((x) => x.id)
              .toArray(),
            contentGroups: linq
              .from(this.model.page.searchContentGroups)
              .where((x) => x.checked == true)
              .select((x) => x.id)
              .toArray(),
            tags: linq
              .from(this.model.page.searchTags)
              .where((x) => x.checked == true)
              .select((x) => x.id)
              .toArray(),
            statuses: linq
              .from(this.model.page.searchStatuses)
              .where((x) => x.checked == true)
              .select((x) => x.id)
              .toArray(),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        if (!this.model.page.searchLoaded) {
          for (const item1 of response.searchInfo.plans) {
            this.model.page.searchPlans.push(
              new model.CheckBox(item1.id, item1.name, false)
            );
          }
          for (const item1 of response.searchInfo.contentGroups) {
            this.model.page.searchContentGroups.push(
              new model.CheckBox(item1.id, item1.name, false)
            );
          }
          for (const item1 of response.searchInfo.tags) {
            this.model.page.searchTags.push(
              new model.CheckBox(item1.id, item1.name, false)
            );
          }
          this.model.page.searchLoaded = true;
        }
        if (!this.model.loadingListProcess) {
          this.model.loadingList = false;
        }
        this.model.page.hitCount = response.hitCount;
        for (const item1 of response.contents) {
          const content = new model.Content(
            item1.code,
            item1.title,
            item1.status,
            item1.thumbnailImage,
            [],
            [],
            []
          );
          for (const item2 of item1.contentGroups) {
            content.contentGroups.push(
              new model.ContentGroup(item2.code, item2.name)
            );
          }
          for (const item2 of item1.plans) {
            content.plans.push(new model.Plan(item2.code, item2.name));
          }
          for (const item2 of item1.tags) {
            content.tags.push(new model.Tag(item2.id, item2.name));
          }
          this.model.contents.push(content);
          this.$watch("model.page.searchPlans", () => this.changeSearch(), {
            deep: true,
          });
          this.$watch(
            "model.page.searchContentGroups",
            () => this.changeSearch(),
            {
              deep: true,
            }
          );
          this.$watch("model.page.searchTags", () => this.changeSearch(), {
            deep: true,
          });
        }
      })
      .catch((ex) => {
        throw ex;
        this.model.loadingList = false;
        if (this.model.page) {
          this.model.page.hitCount = 0;
        }
      });
    this.loadingOff();
  }
  public async create() {
    let response: any;
    this.model.isActiveCreateButton = false;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/Contents/Create`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.$router.push({
          name: "CreatorContentEdit",
          params: { contentCode: response.contentCode },
        });
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();
    this.model.isActiveCreateButton = true;
  }
  public async deleteItem(code: string, name: string) {
    this.model.deleteCode = code;
    this.model.deleteName = name;
    this.model.deleteConfirmText = "";
    this.$modal.show("deleteConfirm");
  }
  public async deleteMessageYes() {
    this.model.deleteLoading = true;
    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/Plans/Main`,
        {
          page: this.model.page.pageCount,
          search: {
            keyword: this.model.page.searchKeyword,
            viewCount: Number(this.model.page.viewCount),
            order: this.model.page.searchOrder,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
        this.loadListRun();
        this.$modal.hide("deleteConfirm");
      })
      .catch((ex) => {
        throw ex;
        this.model.loadingList = false;
        if (this.model.page) {
          this.model.page.hitCount = 0;
        }
      });
    this.loadingOff();
    this.model.deleteLoading = false;
  }
  public async deleteMessageNo() {
    this.$modal.hide("deleteConfirm");
  }
  public sleep(msec: number): Promise<void> {
    return new Promise(function(resolve) {
      setTimeout(function() {
        resolve();
      }, msec);
    });
  }
}
